<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
   <v-container fluid grid-list-xl>
    <!-- <cases-drill-down-sheet -->
    <!--   :show="showDrillDown" -->
    <!--   :items="detailItems" -->
    <!--   @close="showDrillDown = false" -->
    <!-- /> -->
    <v-layout row wrap>
      <v-flex class="d-flex justify-start" lg6 sm6 xs12>
        <v-btn color="info" @click="copyView"> <v-icon left>mdi-share</v-icon> Share View </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex lg3 sm6 xs12>
        <stat-widget
          icon="watch_later"
          :title="totalResponseHours | toNumberString"
          sup-title="Response hours while On-Call"
          icon-color="indigo accent-2"
        />
      </v-flex>
      <v-flex lg3 sm6 xs12>
        <stat-widget
          :icon="oncallSentimentIcon"
          :title="oncallSentiment"
          sup-title="On-Call Sentiment"
          icon-color="deep-orange"
        />
      </v-flex>
      <v-flex lg3 sm6 xs12>
        <stat-widget
          :icon="incidentSentimentIcon"
          :title="incidentSentiment"
          sup-title="BAO Sentiment"
          icon-color="success"
        />
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <dashboard-card
          :loading="loading"
          type="bar"
          :options="chartOptions"
          :series="serviceSeries"
          title="On-Call Feedback Ratings"
        />
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <dashboard-card
          :loading="loading"
          type="bar"
          :options="chartOptions"
          :series="incidentSeries"
          title="BAO Feedback Ratings"
        />
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <dashboard-card
          :loading="loading"
          type="bar"
          :options="chartOptions"
          :series="serviceHoursSeries"
          title="On-Call Service Feedback Hours"
        />
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <dashboard-card
          :loading="loading"
          type="bar"
          :options="chartOptions"
          :series="serviceIdSeries"
          title="On-Call Service Feedback by Services"
        />
      </v-flex>
    </v-layout>
  </v-container>
  </template>

  <script>
  import { forEach, sumBy } from "lodash"
  import DashboardUtils from "@/dashboard/utils"
  import DashboardCard from "@/dashboard/DashboardCard.vue"
  import ServiceFeedbackApi from "@/feedback/service/api"
  import IncidentFeedbackApi from "@/feedback/incident/api"
  import StatWidget from "@/components/StatWidget.vue"

  export default {
    name: "FeedbackOverview",

    components: {
      DashboardCard,
      StatWidget,
    },

    data() {
      return {
        loading: true,
        serviceFeedbackData: [],
        incidentFeedbackData: [],
      }
    },

    created: function () {
      this.fetchServiceFeedbackData()
      this.fetchIncidentFeedbackData()
    },

    methods: {
      async fetchServiceFeedbackData() {
        try {
          let filterOptions = {
            itemsPerPage: -1,
          }
          this.loading = true
          const response = await ServiceFeedbackApi.getAll(filterOptions)
          this.serviceFeedbackData = response.data
        } catch (error) {
          console.error("Failed to fetch feedback data:", error)
        } finally {
          this.loading = false
        }
      },
      async fetchIncidentFeedbackData() {
        try {
          let filterOptions = {
            itemsPerPage: -1,
          }
          this.loading = true
          const response = await IncidentFeedbackApi.getAll(filterOptions)
          this.incidentFeedbackData = response.data
        } catch (error) {
          console.error("Failed to fetch feedback data:", error)
        } finally {
          this.loading = false
        }
      },
      copyView: function () {
      let store = this.$store
      this.$copyText(window.location).then(
        function () {
          store.commit(
            "notification_backend/addBeNotification",
            {
              text: "View copied to clipboard.",
            },
            { root: true }
          )
        },
        function () {
          store.commit(
            "notification_backend/addBeNotification",
            {
              text: "Failed to copy view to clipboard.",
              color: "error",
            },
            { root: true }
          )
        }
      )
      },
    },

    computed: {
      chartOptions() {
        return {
          type: "bar",
          height: 350,
          stacked: true,
          animations: {
            enabled: false,
          },
        }
      },
      serviceSeries() {
        let allRatings = []
        forEach(this.serviceFeedbackData.items, function (value) {
          allRatings.push(value.rating)
        })
        let series = DashboardUtils.createCountedSeriesData(this.serviceFeedbackData, "rating", [
          ...new Set(allRatings),
        ])
        return series
      },
      serviceHoursSeries() {
        let allRatings = []
        forEach(this.serviceFeedbackData.items, function (value) {
          allRatings.push(value.hours)
        })
        let series = DashboardUtils.createCountedSeriesData(this.serviceFeedbackData, "hours", [
          ...new Set(allRatings),
        ])
        return series
      },
      serviceIdSeries() {
        let allRatings = []
        forEach(this.serviceFeedbackData.items, function (value) {
          allRatings.push(value.service.id)
        })
        let series = DashboardUtils.createCountedSeriesData(this.serviceFeedbackData, "service.id", [
          ...new Set(allRatings),
        ])
        return series
      },
      incidentSeries() {
        let allRatings = []
        forEach(this.incidentFeedbackData.items, function (value) {
          allRatings.push(value.rating)
        })
        let series = DashboardUtils.createCountedSeriesData(this.incidentFeedbackData, "rating", [
          ...new Set(allRatings),
        ])
        return series
      },
      categoryData() {
        return Object.keys(this.serviceFeedbackData), Object.keys(this.incidentFeedbackData)
      },
      totalResponseHours() {
        if (!this.serviceFeedbackData.items || this.serviceFeedbackData.items.length === 0) {
          return 0
        }
        let total = 0
        forEach(this.serviceFeedbackData.items, (value) => {
          total += Number(value.hours)
        })
        return total
      },
      averageIncidentScore() {
        if (!this.incidentFeedbackData.items || this.incidentFeedbackData.items.length === 0) {
          return 'No Data'
        }
        const scoreMapping = {
          "Very satisfied": 5,
          "Somewhat satisfied": 4,
          "Neither satisfied nor dissatisfied": 3,
          "Somewhat dissatisfied": 2,
          "Very dissatisfied": 1
        };
        let totalScore = 0
        forEach(this.incidentFeedbackData.items, (value) => {
          totalScore += scoreMapping[value.rating]
        })
        return totalScore / this.incidentFeedbackData.items.length
      },
      averageOncallScore() {
        if (!this.serviceFeedbackData.items || this.serviceFeedbackData.items.length === 0) {
          return 'No Data'
        }
        const scoreMapping = {
          "No effort": 6,
          "Little effort": 5,
          "Moderate effort": 4,
          "Lots of effort": 3,
          "Very high effort": 2,
          "Extreme effort, everything I could give": 1
        };
        let totalScore = 0
        forEach(this.serviceFeedbackData.items, (value) => {
          totalScore += scoreMapping[value.rating]
        })
        return totalScore / this.serviceFeedbackData.items.length
      },
      incidentSentiment() {
        const averageScore = this.averageIncidentScore
        if (averageScore > 3) {
          return "Positive"
        } else if (averageScore >= 2) {
          return "Neutral"
        } else {
          return "Negative"
        }
      },
      oncallSentiment() {
        const averageScore = this.averageOncallScore
        if (averageScore > 4) {
          return "Positive"
        } else if (averageScore >= 2) {
          return "Neutral"
        } else {
          return "Negative"
        }
      },
      incidentSentimentIcon() {
        const incidentSentiment = this.incidentSentiment
        if (incidentSentiment === "Positive") {
          return "check_circle";
        } else if (incidentSentiment === "Neutral") {
          return "remove_circle";
        } else {
          return "cancel";
        }
      },
      oncallSentimentIcon() {
        const oncallSentiment = this.oncallSentiment
        if (oncallSentiment === "Positive") {
          return "check_circle";
        } else if (oncallSentiment === "Neutral") {
          return "remove_circle";
        } else {
          return "cancel";
        }
      },
    },
  }
  </script>
